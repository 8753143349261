<template>
    <div class="app-container">
        Scan all records for discrepancies.
        <p>
            <strong>Note:</strong> this is an intensive operation and should not
            be performed during operating hours.
        </p>
        <el-button :disabled="scanning" type="danger" @click="scan">
            <span v-if="scanning"
                ><i class="el-icon-loading" /> Scanning...</span
            >
            <span v-else>Start scan</span>
        </el-button>
        <div style="padding: 10px 0">
            <el-switch
                v-if="scanning || filteredUsers.length"
                v-model="isFiltered"
                inactive-text="All users"
                active-text="Discrepancies"
            ></el-switch>
        </div>
        <el-alert
            v-if="!!status"
            effect="dark"
            type="warning"
            :closable="false"
            :title="status"
        />
        <template v-for="user in filteredUsers">
            <div
                :key="user.ref"
                class="user-row"
                :class="{
                    fadeOut: isFiltered && user.complete && !user.discrepancy,
                }"
            >
                <i v-if="!user.complete" class="el-icon-loading" />
                <i
                    v-else-if="!user.discrepancy"
                    class="el-icon-check"
                    style="color: green"
                />
                <i v-else class="el-icon-close" style="color: red" />
                <el-tag type="info" class="user-ref">{{ user.ref }}</el-tag>
                {{ user.name }}
                <el-tag v-if="!user.complete" type="info">
                    {{ user.scanningMessage }}
                </el-tag>
            </div>
            <el-table
                v-if="user.budgets.length > 0"
                :key="`${user.ref}_budgets`"
                :data="user.budgets"
            >
                <el-table-column prop="plan" label="Plan Ref" />
                <el-table-column prop="ref" label="Budget Ref" />
                <el-table-column prop="spent" label="Spent" />
                <el-table-column prop="calc" label="Calculated" />
            </el-table>
            <hr :key="user.ref" />
        </template>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'users',
    components: {},
    props: {},
    data() {
        return {
            scanning: false,
            users: [],
            status: null,
            isFiltered: true,
        };
    },
    computed: {
        discrepancyCount() {
            if (this.users && this.users.length > 0) {
                return this.users.filter((u) => u.discrepancy).length;
            }
            return 0;
        },
        filteredUsers() {
            if (this.isFiltered) {
                return this.users.filter((u) => !u.hide);
            }
            return this.users;
        },
    },
    watch: {},
    mounted() {},
    methods: {
        async scan() {
            this.scanning = true;
            this.status = 'Loading users...';

            const userResult = await this.$fire
                .collection('users')
                .orderBy('ndis_number')
                .get();

            if (userResult.size > 0) {
                console.log('Clients found:', userResult.size);
                this.status = `${userResult.size} clients found. scanning...`;

                /* concurrent queries */
                const batchSize = 100; // safe batch size for querying Firebase without tripping rate limits
                const sleepTime = 10000; // wait time between batches
                let count = 0;

                while (count < userResult.size) {
                    const batch = userResult.docs.slice(
                        count,
                        count + batchSize
                    );

                    let fetches = [];
                    for (const userSnapshot of batch) {
                        fetches.push(
                            this.getUserData(userSnapshot).then((userData) => {
                                this.status = `Scanned ${++count} of ${
                                    userResult.size
                                } clients...`;
                                // this.users.push(userData);
                            })
                        );
                    }
                    await Promise.all(fetches);
                    // sleep for API rate limit cooldown
                    this.status = `Sleeping... ${count} of ${userResult.size} scanned`;
                    await new Promise((r) => setTimeout(r, sleepTime));
                    this.status = 'Scanning...';
                }

                /* consecutive queries */
                // let count = 0;
                // for (const userSnapshot of userResult.docs) {
                //     const userData = await this.getUserData(userSnapshot);
                //     this.status = ` Scanned ${++count} clients...`;
                //     this.users.push(userData);
                // }
            } else {
                this.status = 'No clients found.';
            }

            this.status = `${userResult.size} clients found, ${this.discrepancyCount} with discrepancies.`;

            this.scanning = false;
        },

        async getUserData(userSnapshot) {
            const user = userSnapshot.data();

            const userData = {
                ref: userSnapshot.ref.path,
                name: user.name,
                budgets: [],
                scanningMessage: 'Scanning...',
                complete: false,
                hide: false,
                discrepancy: false,
            };

            this.users.push(userData);

            if (user.plans) {
                userData.scanningMessage = `Scanning ${user.plans.length} plans`;

                for (const [p, planRef] of user.plans.entries()) {
                    const planSnapshot = await planRef.get();
                    const plan = planSnapshot.data();

                    if (plan.data) {
                        userData.scanningMessage = `Scanning plans [${p + 1}/${
                            user.plans.length
                        }], ${plan.data.length} budgets`;

                        for (const [b, planData] of plan.data.entries()) {
                            const budgetSnapshot = await planData.budget.get();
                            const budget = budgetSnapshot.data();

                            const budgetData = {
                                plan: planRef.path,
                                ref: budgetSnapshot.ref.path,
                                spent: budget.budget_spent,
                                calc: 0,
                                missing: 0,
                            };

                            if (budget.claims) {
                                userData.scanningMessage = `Scanning plans [${
                                    p + 1
                                }/${user.plans.length}], budgets [${b + 1}/${
                                    plan.data.length
                                }], ${budget.claims.length} claims`;

                                // get all claims in one batch
                                const fetchClaims = [];
                                for (const [
                                    c,
                                    claimRef,
                                ] of budget.claims.entries()) {
                                    fetchClaims.push(
                                        claimRef.get().then((claimSnapshot) => {
                                            const claim = claimSnapshot.data();
                                            if (
                                                claim &&
                                                claim.approval_status !==
                                                    'rejected'
                                            ) {
                                                userData.scanningMessage = `Scanning plans [${
                                                    p + 1
                                                }/${
                                                    user.plans.length
                                                }], budgets [${b + 1}/${
                                                    plan.data.length
                                                }], claims [${c + 1}/${
                                                    budget.claims.length
                                                }]`;
                                                budgetData.calc += claim.total;
                                            } else {
                                                budgetData.missing++;
                                            }
                                        })
                                    );
                                }
                                console.log(
                                    user.name,
                                    ': Processing',
                                    fetchClaims.length,
                                    'claims'
                                );
                                await Promise.all(fetchClaims);
                            }

                            if (
                                budgetData.spent.toFixed(2) !==
                                budgetData.calc.toFixed(2)
                            ) {
                                userData.discrepancy = true;
                                userData.budgets.push(budgetData);
                            }
                        }
                    }
                }
            }
            userData.complete = true;
            setTimeout(() => {
                if (!userData.discrepancy) {
                    userData.hide = true;
                }
            }, 1500);
            return userData;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-alert {
    margin: 20px 0;
}
.user-row {
    transition: opacity 0.5s linear 1s;
    &.fadeOut {
        opacity: 0.2;
    }
}
.user-ref {
    font-family: monospace;
}
</style>
