var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm._v(" Scan all records for discrepancies. "),
      _vm._m(0),
      _c(
        "el-button",
        {
          attrs: { disabled: _vm.scanning, type: "danger" },
          on: { click: _vm.scan },
        },
        [
          _vm.scanning
            ? _c("span", [
                _c("i", { staticClass: "el-icon-loading" }),
                _vm._v(" Scanning..."),
              ])
            : _c("span", [_vm._v("Start scan")]),
        ]
      ),
      _c(
        "div",
        { staticStyle: { padding: "10px 0" } },
        [
          _vm.scanning || _vm.filteredUsers.length
            ? _c("el-switch", {
                attrs: {
                  "inactive-text": "All users",
                  "active-text": "Discrepancies",
                },
                model: {
                  value: _vm.isFiltered,
                  callback: function ($$v) {
                    _vm.isFiltered = $$v
                  },
                  expression: "isFiltered",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !!_vm.status
        ? _c("el-alert", {
            attrs: {
              effect: "dark",
              type: "warning",
              closable: false,
              title: _vm.status,
            },
          })
        : _vm._e(),
      _vm._l(_vm.filteredUsers, function (user) {
        return [
          _c(
            "div",
            {
              key: user.ref,
              staticClass: "user-row",
              class: {
                fadeOut: _vm.isFiltered && user.complete && !user.discrepancy,
              },
            },
            [
              !user.complete
                ? _c("i", { staticClass: "el-icon-loading" })
                : !user.discrepancy
                ? _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: { color: "green" },
                  })
                : _c("i", {
                    staticClass: "el-icon-close",
                    staticStyle: { color: "red" },
                  }),
              _c(
                "el-tag",
                { staticClass: "user-ref", attrs: { type: "info" } },
                [_vm._v(_vm._s(user.ref))]
              ),
              _vm._v(" " + _vm._s(user.name) + " "),
              !user.complete
                ? _c("el-tag", { attrs: { type: "info" } }, [
                    _vm._v(" " + _vm._s(user.scanningMessage) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          user.budgets.length > 0
            ? _c(
                "el-table",
                { key: `${user.ref}_budgets`, attrs: { data: user.budgets } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "plan", label: "Plan Ref" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ref", label: "Budget Ref" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "spent", label: "Spent" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "calc", label: "Calculated" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("hr", { key: user.ref }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Note:")]),
      _vm._v(
        " this is an intensive operation and should not be performed during operating hours. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }